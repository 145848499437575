import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../../css/testimonial.scss"

const CompletedHomesPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-2.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Completed Homes</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<div className="testimonial">
						<StaticImage src="../../images/completed-builds/phelps.JPG" alt="Phelps Testimonial" class="testimonialImage" />
						<div className="testimonialContent">
							<h6>
								"Habitat for Humanity is a fantastic group of people with generous, caring hearts who are making dreams come true.  
								We will always be grateful for our time working with them and will always remember them with fondness in our hearts.""
							</h6>
							<p>- Rebecca Robbins  (Habitat homeowner from Phelps)</p>
						</div>
					</div>
					<div className="testimonial">
						<div className="testimonialContent">
							<h6>
								"My Habitat for Humanity home was my chance for independence. As a single mom this was huge. I couldn't be happier."
							</h6>
							<p>- Belit Babilius (Habitat homeowner from Lac du Flambeau)</p>
						</div>
						<StaticImage src="../../images/completed-builds/lac-du-flambeau.JPG" alt="Lac Du Flambeau Testimonail" class="testimonialImage" />
					</div>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default CompletedHomesPage

export const Head = () => (
	<Seo
        title="Completed Homes | Habitat For Humanity Northwoods Wisconsin"
    />
)